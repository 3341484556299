import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1><strong>Outside In</strong><br/>&nbsp;
                        <span>Musculoskeletal Therapy and Clinical Myotherapy</span>
                    </h1>
                    <p><i>by Kathryn Cross</i></p>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
