import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
            <div className="inner">
                <ul className="icons">
                    <li><a href="tel:07 5447 8004" className="icon fa-phone"><span className="label"></span></a></li>
                    <li><a href="mailto:outsidein@outsidein.biz" className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    <li><a href="https://www.facebook.com/outsidein.biz/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>We encourage longevity in health and wellness through preventative medicine and lifestyle change. We aim to provide a bridge between the medical profession, complementary practitioners and clients seeking assistance.</li>
                </ul>
            </div>
        </div>
        )
    }
}

export default Footer
